<template>
<!--   <hat></hat> -->
  <news></news>
</template>
<script setup lang="ts">
 // import hat from './hat.vue' 
 import news from './news.vue'
// import { useLogStore } from './store.js'  
// const store = useLogStore()

// import { ref, computed, watch, reactive, onMounted, onBeforeMount, toRefs } from 'vue'

// const plain: any = inject('plain')
// const secured: any = inject('secured')

onMounted(() => {
 
})
 
</script>

<style scoped  lang="scss" >
 
</style>